<template>
  <div>
    <div class="table-responsive">
      <table class="table b-table table-fixed">
        <thead>
          <tr>
            <th>Pengirim</th>
            <th>Nomor Invoice</th>
            <th>Masalah</th>
            <th>Solusi</th>
            <th>Waktu</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoadingComponent">
            <td
              colspan="5"
              class="text-center"
            >
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr
            v-for="item in resultData.data"
            v-else
            :key="item.id"
          >
            <td>
              <a
                class="text-primary"
                @click="goToDetail(item.sender_type, item.sender.uuid)"
              >
                {{ item.sender.name ? item.sender.name : '-' }}
              </a>
            </td>
            <td>
              {{ item.order.invoice_number ? item.order.invoice_number : '-' }}
            </td>
            <td>
              {{ item.problem ? item.problem : '-' }}
            </td>
            <td>
              {{ item.expected_solution ? item.expected_solution : '-' }}
            </td>
            <td>
              {{ item.time ? item.time : '-' }}
            </td>
            <td>
              <b-dropdown
                variant="info"
                size="sm"
              >
                <template #button-content>
                  Action
                </template>
                <b-dropdown-item
                  v-if="checkPermission('show komplain')"
                  @click="detailKomplain(item.uuid)"
                >
                  <feather-icon
                    icon="UsersIcon"
                    class="mr-75"
                  />
                  Detail Keluhan
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td
              colspan="5"
              class="text-center"
            >
              Data is empty.
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="resultData.total > 0"
        class="m-1"
      >
        <div class="row">
          <div class="col mb-1">
            <small>Showing {{ resultData.from }} to {{ resultData.to }} from {{ resultData.total }}</small>
          </div>
          <div class="col">
            <pagination
              :data="resultData"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BSpinner,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BSpinner,
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    result: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
    },
    getData: {
      type: Function,
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      isLoadingComponent: true,
      resultData: {},
    }
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value
    },
    result(value) {
      this.resultData = value
    },
  },
  methods: {
    detailKomplain(id) {
      this.$router.push({ name: 'complaint.show', params: { id } })
    },
    goToDetail(senderType, id) {
      const type = {
        'App\\Models\\Talent\\Talent': 'talenta.show',
        'App\\Models\\User': 'users.show',
      }

      this.$router.push({ name: type[senderType], params: { id } })
    },
  },
}
</script>

<style lang="scss">

</style>
